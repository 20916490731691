import react, { useState } from 'react';
import useAuth from '../hooks/useAuth';
import SettingsOptions from '../components/SettingsOptions';
import useNotifications from '../hooks/useNotifications';
import { v4 } from 'uuid';
import axios from 'axios';
import Modal from '../components/Modal';
import AddWallets from '../components/AddWallets';
import WalletContents from '../components/WalletContents';
import WalletManagement from '../components/WalletManagement';

function Settings() {
    const { auth, user } = useAuth();
    const [settingState, setSettingState] = useState("Account");
    const { notifDispatch } = useNotifications();
    const [ showModal, setShowModal] = useState(false);

    const toggleModalOn = (e) => {
        e.preventDefault();
        setShowModal(true);
    }
    const toggleModalOff = (e) => {
        e.preventDefault();
        setShowModal(false);
    }

    const resetPassword = () => {
        const axiosInstance = axios.create({baseURL: "https://email-auth.vtsxcode.xyz"});
        //console.log(user.nakamaSession);
        axiosInstance.post('/reset', {session:user.nakamaSession}, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then(() => {
            notifDispatch({
                type: "ADD_NOTIFICATION",
                payload: {
                    id: v4(),
                    code: "success",
                    message: "Password reset successfully sent"
                }
            })
        }).catch(() => {
            notifDispatch({
                type: "ADD_NOTIFICATION",
                payload: {
                    id: v4(),
                    code: "error",
                    message: "Error sending reset request"
                }
            });
        });
    };

    const renderSettings = () => {
        switch (settingState) {
            case "Account":
                return (
                    <div className="accountOptionsContainer">
                        <h2>{auth.username}</h2>
                        <button className='addFriendButton' onClick={() => {resetPassword();}}>Reset Password</button>
                    </div>
                );
            case "Wallets":
                return (
                    <div>
                        <button className='addFriendButton' onClick={toggleModalOn}>Add Wallet</button>
                        {showModal ? <Modal handleClose={toggleModalOff} children={<AddWallets setModalState={setShowModal}/>} /> : <></>}
                        <WalletManagement />
                    </div>
                );
            case "Inventory":
                return (
                    <div>
                        <WalletContents />
                    </div>
                );
            default:
                break;
        }
    };

    return (
        <div className="wholeSettings">
            <div className="leftSettings">
                <SettingsOptions set_state={setSettingState} curr_state={settingState}/>
            </div>
            <div className="rightSettings">
                <div className="rightSettingsContainer">{renderSettings()}</div>
            </div>
        </div>
    );
};

export default Settings;