import React, { useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import axios from 'axios';
import { v4 } from 'uuid';

function WalletContents() {
    const { user } = useAuth();
    const [assets, setAssets ] = useState([]);
    const [totalAda, setTotalAda] = useState(0);
    const [totalUsd, setTotalUsd] = useState(0);

    useEffect(() => {
        renderAssets();
    }, []);

    useEffect(() => {
        //adaToUSD();
    }, [assets]);

    /*const adaToUSD = async () => {
        axios({method: 'get', url: 'https://api.coingecko.com/api/v3/simple/price?ids=cardano&vs_currencies=usd'}).then((res) => {
            setTotalUsd(res.data.cardano.usd * totalAda);
        });
    };*/
    const renderAssets = async () => {
        let session = user.nakamaSession;
        axios({method: 'post', url: 'https://wallet-auth.vtsxcode.xyz/getcontents', data: {session: session}}).then((result) => {
            let returnAssetsComponents = [];
            if (result.status === 200) {
                for (let wallet in result.data) {
                    for (let asset in result.data[wallet]) {
                        if (result.data[wallet][asset].image !== undefined &&  result.data[wallet][asset].image.includes('ipfs://')) {
                            result.data[wallet][asset].image = result.data[wallet][asset].image.replace('ipfs://', 'https://ipfs.io/ipfs/');
                            returnAssetsComponents.push(result.data[wallet][asset]);
                        }
                        else if (result.data[wallet][asset].image !== undefined) {
                            result.data[wallet][asset].image = 'data:image/gif;base64,' + result.data[wallet][asset].image;
                            returnAssetsComponents.push(result.data[wallet][asset]);
                        }
                        else if (result.data[wallet][asset].name == "ADA") {
                            setTotalAda((totalAda+result.data[wallet][asset].quantity));
                        }
                    }
                }
            }
            setAssets(returnAssetsComponents);
        });
    }

    return (
        <>
            <div className="assetcontainer">
                {assets.map(asset => { return <AssetCard key={v4()} {...asset} /> })}
            </div>
        </>
    );
}

function AssetCard({name, image, quantity, floor}) {
    return (
        <div className="assetcard">
            <img className="assetimage" src={image} alt="" />
            <p className="assetname">{name}</p>
            <p className="assetquantity">{quantity}</p>
            <p className="assetquantity">{floor}</p>
        </div>
    )
}

export default WalletContents;
