import React, { useState } from "react";
import "../App.css";
import useNotifications from "../hooks/useNotifications";
import { v4 } from "uuid";

function SignUpForm({ SignUpProp, ErrorProp }) {
  const [details, setDetails] = useState({
    email: "",
    username: "",
    password: "",
  });
  const { notifDispatch } = useNotifications();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const usernameRegex = /^[a-zA-Z0-9]{3,12}$/;

  const submitHander = (e) => {
    e.preventDefault();
    if (details.password.length >= 8 && emailRegex.test(details.email) && usernameRegex.test(details.username)) {
      SignUpProp(details);
    }
    else {
      if (details.password.length < 8) {
        notifDispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            id: v4(),
            code: "error",
            message: "password must be at least 8 characters"
          }
        });
      }
      if (!emailRegex.test(details.email)) {
        notifDispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            id: v4(),
            code: "error",
            message: "invaild email format"
          }
        });
      }
      if (!usernameRegex.test(details.username)) {
        if (details.username.length > 12 || details.username.length < 3) {
          notifDispatch({
            type: "ADD_NOTIFICATION",
            payload: {
              id: v4(),
              code: "error",
              message: "username must be between 12 and 3 characters long"
            }
          });
        }
        else {
          notifDispatch({
            type: "ADD_NOTIFICATION",
            payload: {
              id: v4(),
              code: "error",
              message: "usernames cannot contain spaces or special characters"
            }
          });
        }
      }
    }
  };

  return (
    <form onSubmit={submitHander}>
      <div className="form">
        <h2>SignUp</h2>
        {/* ERROR */}
        <div className="infoForm">
          <label htmlFor="email">Email </label>
          <input
            type="text"
            name="email"
            id="email"
            onChange={(e) => setDetails({ ...details, email: e.target.value })}
            value={details.email}
          />
        </div>
        <div className="infoForm">
          <label htmlFor="username">Username </label>
          <input
            type="text"
            name="username"
            id="username"
            onChange={(e) =>
              setDetails({ ...details, username: e.target.value })
            }
            value={details.username}
          />
        </div>
        <div className="infoForm">
          <label htmlFor="password">Password </label>
          <input
            type="password"
            name="password"
            id="password"
            onChange={(e) =>
              setDetails({ ...details, password: e.target.value })
            }
            value={details.password}
          />
        </div>
        <input type="submit" value="Signup" />
      </div>
    </form>
  );
}

export default SignUpForm;
