import React, { useState } from "react";
import SignUpForm from "../components/SignUpForm";
import axios from "axios";
import logo from "../assets/images/primary-Logo.png";
import useNotifications from "../hooks/useNotifications";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";

function SignUp() {
  const [user, setUser] = useState({ username: "", email: "", password: "" });
  const [error, setError] = useState("");
  const [code, setCode] = useState(0);
  const { notifDispatch } = useNotifications();
  const navigate = useNavigate();

  const SignUpFunc = (details) => {
    const axiosInstance = axios.create({
      baseURL: "https://email-auth.vtsxcode.xyz",
    });
    //console.log(`email: ${details.email}, username: ${details.username}, payload: ${details.password}`);
    axiosInstance
      .post(
        "/signup",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            email: details.email,
            username: details.username,
            payload: details.password,
          },
        }
      )
      .then((result) => {
        //console.log(result);
        setCode(result.status);
        if (result.status === 200) {
          notifDispatch({
            type: "ADD_NOTIFICATION",
            payload: {
              id: v4(),
              code: "success",
              message: "Account successfully created!"
            }
          });
          navigate("/login");
        }
      }).catch(err => {
        notifDispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            id: v4(),
            code: "error",
            message: "Email or username already in use"
          }
        });
      });
  };

  return (
    <div>
      <div>
        <img src={logo} alt="Primary Logo" className="primaryLogo" />;
        <SignUpForm SignUpProp={SignUpFunc} ErrorProp={error} />
      </div>
    </div>
  );
}

export default SignUp;
