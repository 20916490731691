import React, { createContext, useReducer } from "react";
import Notification from "../components/Notification";

const notificationContext = createContext({});

export const NotificationProvider = ({children}) => {
    const [notifState, notifDispatch] = useReducer((state, action) => {
        switch(action.type) {
            case "ADD_NOTIFICATION":
                //console.log("adding notification");
                return [...state, {...action.payload}];
            case "REMOVE_NOTIFICATION":
                //console.log("removing notification: " + action.id);
                //console.log("state: " + state);
                return state.filter(element => element.id !== action.id);
            default:
                //console.log("state: " + state);
                return state;
        }
    }, []);

    return (
        <notificationContext.Provider value={{notifDispatch}}>
             <div className="notification-section">
                {
                    notifState.map(element => {
                        return <Notification key={element.id} notifDispatch={notifDispatch} {...element}/>
                    })
                }
            </div>
            {children}
        </notificationContext.Provider>
    );
}

export default notificationContext;
