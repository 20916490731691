import React from 'react';
import { FiTrash2 } from "react-icons/fi";
import axios from 'axios';

function WalletManagementButton({wallet, address, sess}) {

    const removeWallet = async (e) => {
        e.preventDefault();
        //console.log(`Removing ${wallet}`);

        axios({method: 'POST', url: 'https://wallet-auth.vtsxcode.xyz/removewallet', data: {address: address, session: sess } });
    }

    return(
        <div className="walletmanagementbtn">
            <label>{address}</label>
            <button onClick={(e) => {removeWallet(e)}}><FiTrash2 size={32} /></button>
        </div>
    );
}

export default WalletManagementButton;
