import React, { useState, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import useRefresh from '../hooks/useRefresh';
import useAuth from '../hooks/useAuth';
import Loading from '../pages/Loading';

const PersistantLogin = () => {
    const ranEffect = useRef(false);
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefresh();
    const { user } = useAuth();

    useEffect(() => {
        if (ranEffect.current === false) {
            const verifyRefresh = async () => {
                try {
                    await refresh();
                }
                catch (e) {

                }
                finally {
                    setIsLoading(false);
                }
            }
            !user?.nakamaSession ? verifyRefresh() : isLoading(false);
            ranEffect.current = true;
        }
    }, []);

    return (
        <>
            { isLoading ? <Loading/> : <Outlet/> }
        </>
    )
};

export default PersistantLogin;