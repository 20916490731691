import { Link } from 'react-router-dom';
import { IoIosLogIn } from 'react-icons/io';
import { BsGear } from 'react-icons/bs';
import useAuth from "../hooks/useAuth";

function NavBar() {
  const { user } = useAuth();
  return (
    <div className="wholeNav">
      <div className="innerNav">
        <h2>Fragmint</h2>
        <div id='icons'>
          {user.nakamaSession?.username? <Link to="settings"><BsGear size={32}/></Link> : <></>}
          <Link to="dashboard"><IoIosLogIn size={32}/></Link>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
